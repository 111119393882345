import React from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBFooter, MDBIcon } from 'mdb-react-ui-kit';
import './Styles/aboutUs.css';
import one from '../images/landing.webp';
import { Link, useNavigate } from "react-router-dom";

const AboutUs = () => {
  return (
    <div>
      {/* About Section */}
      <div className="container-fluid overflow-hidden py-5 px-lg-0">
        <div className="container about py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: '400px' }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src={one}
                  style={{ objectFit: 'cover' }}
                  alt="About Us"
                />
              </div>
              
            </div>
            <div className="col-lg-6 about-text">
              <h6 className="text-secondary text-uppercase mb-3">About Us</h6>
              <h1 className="mb-5">Quick Delivery For Your Childs School Booklist</h1>
              <p className="mb-5">
              Unlock your child's academic success with ease! Simply search for their school, choose the grade, and with just a few clicks, place your order. We'll deliver their complete school booklist right to your doorstep, ensuring they are ready for the school year ahead!
              </p>
              <Link to="/sign-in" className="btn btn-primary py-3 px-5">
                  Sign In
                </Link>
                <Link to="/sign-up" className="btn btn-success py-3 px-5 " style={{ marginLeft: '10px'}} >
                  Sign up
                </Link>
              
              
          

<p/>
              <div className="row g-4 mb-5">
                <div className="col-sm-6">
                  <i className="fa fa-globe fa-3x text-primary mb-3"></i>
                  <h5>Coverage</h5>
                  <p className="m-0">We ensure your child’s school Booklist reach your home with reliable transportation.</p>
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i>
                  <h5>On-Time Delivery</h5>
                  <p className="m-0">We pride ourselves on delivering your goods on time, every time, without delays.</p>
                </div>
              </div>
              


            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <MDBFooter style={{ backgroundColor: 'silver'}} className="text-center text-lg-start text-muted">
        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <MDBIcon icon="gem" className="me-3" />
                  Skool Booklist
                </h6>
                <p>
                  Struggling to find your child’s school booklist at the bookshop? Place your order and have the books delivered to your doorstep.
                </p>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="#!" className="text-reset">CBC curriculum Books</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">International curriculum books</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Stationery</a>
                </p>
              </MDBCol>
              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful Links</h6>
                <p>
                  <a href="#!" className="text-reset">Pricing</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Orders</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Help</a>
                </p>
              </MDBCol>
              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Nairobi, Kenya
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  info@skoolbooklist.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> +254 797 230 330
                </p>
                <p>
                  <MDBIcon icon="print" className="me-3" /> +254 705 769 873
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
};

export default AboutUs;
