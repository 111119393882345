import React, { useState, useEffect } from "react";
import { remove, ref, onValue, update } from "firebase/database";
import { useAuth } from "../../contexts/authContext";
import { database } from "../../firebase/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaShoppingCart, FaHome, FaUser, FaSignOutAlt, FaSearch, FaClipboardList } from 'react-icons/fa';
import '../Styles/orders.css';
import whatPng from '../../images/file.png';
import whaPng from "../../images/SD.png";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { trackOrder, deleteOrder, orderDate, trackingLink, getOrderId } from "../../shipdayService";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();  // Get location state
  const { logout, currentUser } = useAuth();
  const [cartItems, setCartItems] = useState([]);
  const [showLoginSignup, setShowLoginSignup] = useState(false);

 

  useEffect(() => {
    if (currentUser) {
      const ordersRef = ref(database, `orders/${currentUser.uid}`);
      onValue(ordersRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const orderList = await Promise.all(
            Object.keys(data).map(async (key) => {
              const order = { id: key, ...data[key] };
    
              // Skip orders marked as deleted
              if (order.deleted) {
                return null;
              }
    
              // Fetch the order status from the tracking service
              try {
                const status = await trackOrder(order.orderNumber);
                const dateStatus = await orderDate(order.orderNumber);
                const trackingLinkStatus = await trackingLink(order.orderNumber);
                order.status = status;
                order.date = dateStatus;
                order.trackingLink = trackingLinkStatus;
              } catch (error) {
                console.error("Error fetching order status:", error);
                order.status = "Error fetching status";
              }
    
              return order;
            })
          );
  
          // Remove any null values from the list
          const filteredOrderList = orderList.filter(order => order !== null);
  
          // Sort the orders by date from latest to oldest
          const sortedOrderList = filteredOrderList.sort((a, b) => new Date(b.date) - new Date(a.date));
  
          setOrders(sortedOrderList);
        } else {
          setOrders([]);
        }
      });
    }
  }, [currentUser]);
  
  const handleCancelOrder = async (orderId, status) => {
    try {
      const order = orders.find((order) => order.id === orderId);
      
      if (status === "NOT_ASSIGNED" && order) {
        // Fetch the Shipday order ID using the order number
        const shipdayOrderId = await getOrderId(order.orderNumber);
        
        // Delete the order on Shipday using the retrieved order ID
        if (shipdayOrderId) {
          await deleteOrder(shipdayOrderId);
        }
      }
  
      // Remove the order from Firebase
      const orderRef = ref(database, `orders/${currentUser.uid}/${orderId}`);
      await remove(orderRef);
  
      // Update the UI by removing the order from the list
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
  
    } catch (error) {
      setError("Failed to cancel the order. Please try again.");
      console.error("Failed to cancel order:", error);
    }
  };
  
  const handleDeleteOrder = async (orderId) => {
    try {
      const orderRef = ref(database, `orders/${currentUser.uid}/${orderId}`);
      
      // Update the 'deleted' field to true
      await update(orderRef, { deleted: true });
  
      // Update the UI by filtering out deleted orders
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
    } catch (error) {
      setError("Failed to delete the order. Please try again.");
      console.error("Failed to delete order:", error);
    }
  };

  const handleOrderDetails = (orderId) => {
    navigate(`/order-details/${orderId}`);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <MDBContainer className="my-5">
      <header className="nus">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-3">
              <img src={whatPng} className="logo" alt="Logo" />
              <img
                src={whaPng}
                className="logo"
                style={{
                  width: '80px',
                  height: '120px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
                alt="Logo"
              />
            </div>
            <div className="col-md-9">
              <nav className="navbar navbar-expand-lg navbar-primary">
                <div
                  className={`collapse navbar-collapse ${showLoginSignup ? 'show' : ''
                    }`}
                  id="navbarTogglerDemo02"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/home">
                        <FaHome className="icon" /> Home
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/school">
                        <FaSearch className="icon" /> School
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/cart">
                        <FaShoppingCart /> Cart
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/orders">
                        <FaClipboardList /> Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" onClick={handleLogout} to="/sign-in">
                        <FaSignOutAlt className="icon" /> Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <h1>Your Orders</h1>
      {error && <p className="text-danger">{error}</p>}
      <MDBRow>
        {orders.length > 0 ? (
          orders.map((order) => (
            <MDBCol key={order.id} md="4" className="mb-4">
              <MDBCard className="card-stepper" style={{ borderRadius: "16px", width: "100%" }}>
                <MDBCardHeader className="p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="text-muted mb-2">
                        Order ID{" "}
                        <span className="fw-bold text-body">{order.id}</span>
                      </p>
                      <p className="text-muted mb-0">
                        Placed On{" "}
                        <span className="fw-bold text-body">
                          {new Date(order.date).toLocaleDateString()}
                        </span>
                      </p>
                    </div>
                    <div>
                      <MDBTypography tag="h6" className="mb-0">
                        <a href="#" onClick={() => handleOrderDetails(order.id)}>
                          View Details
                        </a>
                      </MDBTypography>
                    </div>
                  </div>
                </MDBCardHeader>
                <MDBCardBody className="p-4">
                  <MDBTypography tag="h5" className="bold">
                    {order.productName}
                  </MDBTypography>
                  <p className="text-muted">Qt: {order.quantity} item(s)</p>
                  
                  <p className="text-muted">Track order: <a href={order.trackingLink} target="_blank" rel="noopener noreferrer">{order.trackingLink}</a></p>
 
                  <MDBTypography tag="h4" className="mb-3">
                    ${order.totalOrderCost}
                  </MDBTypography>
                  <p className="text-muted">
                    Status:{" "}
                    <span className="text-body">{order.status || "Fetching status..."}</span>
                  </p>
                  {order.status === "ALREADY_DELIVERED" ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteOrder(order.id)}
                    >
                      Delete Order
                    </button>
                  ) : order.status === "NOT_ASSIGNED" ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => handleCancelOrder(order.id, order.status)}
                    >
                      Cancel Order
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning"
                      onClick={() => alert("Call customer care to cancel Request 0797230330")}
                    >
                     Call Customer care to cancel
                    </button>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))
        ) : (
          <p>No orders found.</p>
        )}
      </MDBRow>
      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">&copy; 2024 Booklist Shop. All rights reserved.</p>
        </div>
      </footer>
      <div className="bottom-nav d-md-none nus">
        <Link className="nav-link" to="/home">
          <FaHome className="icon" />
          Home
        </Link>
        <Link className="nav-link" to="/school">
          <FaSearch className="icon" />
          School
        </Link>
        <Link className="nav-link" to="/cart">
          <FaShoppingCart className="icon" />
          Cart
        </Link>
        <Link className="nav-link" to="/orders">
          <FaClipboardList className="icon" />
          Orders
        </Link>
        <Link className="nav-link" onClick={handleLogout} to="/sign-in">
          <FaSignOutAlt className="icon" />
          Logout
        </Link>
      </div>
    </MDBContainer>
  );
}

export default Orders;
