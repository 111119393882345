import React, { useState, useEffect } from 'react';
import { database } from '../firebase/firebase'; // Adjust the path as necessary
import { ref, set, onValue, remove } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Papa from 'papaparse';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { FaHome, FaChartBar, FaClipboardList, FaSignOutAlt, FaPlusSquare } from 'react-icons/fa';
import './AdminDashboard.css';

const AdminAdd = () => {
  const [schools, setSchools] = useState([]);
  const [schoolName, setSchoolName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [grades, setGrades] = useState({});
  const [currentSchool, setCurrentSchool] = useState('');
  const [currentGrade, setCurrentGrade] = useState('');
  const [bookName, setBookName] = useState('');
  const [bookPrice, setBookPrice] = useState('');
  const [bookImage, setBookImage] = useState(null);
  const [itemType, setItemType] = useState('Compulsory'); // Default to 'Compulsory'
  const [booklist, setBooklist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const storage = getStorage();

  useEffect(() => {
    const schoolsRef = ref(database, 'schools/');
    onValue(schoolsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSchools(Object.keys(data));
        setGrades(data);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentSchool && currentGrade) {
      const bookKey = `schools/${currentSchool}/grades/${currentGrade}/books/`;
      onValue(ref(database, bookKey), (snapshot) => {
        const books = snapshot.val();
        setBooklist(books ? Object.values(books) : []);
      });
    }
  }, [currentSchool, currentGrade]);

  const addSchool = () => {
    const newGrades = [
      'PP1', 'PP2', 'Grade 1', 'Grade 2', 'Grade 3',
      'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7',
      'Grade 8', 'Grade 9',
    ];
    set(ref(database, `schools/${schoolName}`), { grades: newGrades })
      .then(() => setSchoolName(''));
  };

  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const addBook = () => {
    if (!currentSchool || !currentGrade || !bookImage) return;

    const bookKey = `schools/${currentSchool}/grades/${currentGrade}/books/`;
    const newBookId = new Date().getTime();
    const newBook = { id: newBookId, name: bookName, price: parseFloat(bookPrice), type: itemType };

    // Upload image to Firebase Storage
    const imageRef = storageRef(storage, `books/${newBookId}`);
    uploadBytes(imageRef, bookImage).then(() => {
      getDownloadURL(imageRef).then((url) => {
        newBook.imageUrl = url; // Add image URL to book data
        set(ref(database, `${bookKey}${newBookId}`), newBook)
          .then(() => {
            setBookName('');
            setBookPrice('');
            setBookImage(null);
            setItemType('Compulsory'); // Reset to default type
          });
      });
    });
  };

  const deleteBook = (bookId) => {
    const bookKey = `schools/${currentSchool}/grades/${currentGrade}/books/${bookId}`;
    remove(ref(database, bookKey)).then(() => {
      setBooklist((prevBooks) => prevBooks.filter(book => book.id !== bookId));
    });
  };

  const uploadCSV = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          results.data.forEach((row) => {
            const [schoolName] = row;
            if (schoolName) {
              const newGrades = [
                'PP1', 'PP2', 'Grade 1', 'Grade 2', 'Grade 3',
                'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7',
                'Grade 8', 'Grade 9',
              ];
              const safeSchoolName = schoolName.replace(/[\s.]/g, '_');
              set(ref(database, `schools/${safeSchoolName}`), { grades: newGrades });
            }
          });
        },
      });
    }
  };

  const filteredSchools = searchQuery
    ? schools.filter((school) => school.toLowerCase().includes(searchQuery.toLowerCase()))
    : schools;

  return (
    <div className="admin-dashboard">
      <aside className="sidebar">
        <h2>Admin Dashboard</h2>
        <nav>
          <ul>
            <li>
              <Link to="/admin"><FaHome /> Home</Link>
            </li>
            <li>
              <Link to="/admin/orders"><FaClipboardList /> Orders Per School</Link>
            </li>
            <li>
              <Link to="/admin/add"><FaPlusSquare /> Add School / Books</Link>
            </li>
            <li>
              <Link to="/admin/stats"><FaChartBar /> Statistics</Link>
            </li>
            <li>
              <Link onClick={handleLogout} to="/login"><FaSignOutAlt /> Logout</Link>
            </li>
          </ul>
        </nav>
      </aside>
      <main className="content">
        <h2 className="text-center">Admin Interface</h2>

        <div className="mb-4">
          <h3>Upload Schools CSV</h3>
          <input
            type="file"
            accept=".csv"
            onChange={uploadCSV}
            className="form-control"
          />
        </div>
        <div className="mb-4">
          <h3>Add School</h3>
          <input
            type="text"
            className="form-control"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
            placeholder="School Name"
          />
          <button className="btn btn-primary mt-2" onClick={addSchool}>
            Add School
          </button>
        </div>

        {schools.length > 0 && (
          <div className="mb-4">
            <h3>Add Books</h3>
            <select
              className="form-select mb-2"
              value={currentSchool}
              onChange={(e) => {
                setCurrentSchool(e.target.value);
                setCurrentGrade('');
                setBooklist([]); // Clear booklist when school changes
              }}
            >
              <option value="">Select School</option>
              {filteredSchools.map((school, index) => (
                <option key={index} value={school}>
                  {school}
                </option>
              ))}
            </select>
            <select
              className="form-select mb-2"
              value={currentGrade}
              onChange={(e) => setCurrentGrade(e.target.value)}
            >
              <option value="">Select Grade</option>
              {grades[currentSchool] && (
                <>
                  <option value="PP1">PP1</option>
                  <option value="PP2">PP2</option>
                  {Array.from({ length: 9 }, (_, i) => `Grade ${i + 1}`).map(
                    (grade, index) => (
                      <option key={index} value={grade}>
                        {grade}
                      </option>
                    )
                  )}
                </>
              )}
            </select>
            <input
              type="text"
              className="form-control mb-2"
              value={bookName}
              onChange={(e) => setBookName(e.target.value)}
              placeholder="Book Name"
            />
            <input
              type="number"
              className="form-control mb-2"
              value={bookPrice}
              onChange={(e) => setBookPrice(e.target.value)}
              placeholder="Book Price"
            />
            <select
              className="form-select mb-2"
              value={itemType}
              onChange={(e) => setItemType(e.target.value)}
            >
              <option value="Compulsory">Compulsory</option>
              <option value="Complementary">Complementary</option>
              <option value="Stationery">Stationery</option>
            </select>
            <p>Upload Book Image</p>
            <input
              type="file"
              className="form-control mb-2"
              accept="image/*"
              onChange={(e) => setBookImage(e.target.files[0])}
            />
            <button className="btn button-order" onClick={addBook}>
              Add Book
            </button>
          </div>
        )}

        <h3 className="mt-4">Book List</h3>
        {booklist.length > 0 ? (
          <ul className="list-group">
            {booklist.map((book) => (
              <li key={book.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <img src={book.imageUrl} alt={book.name} style={{ width: '50px', marginRight: '10px' }} />
                  {book.name} - {book.price} (Type: {book.type})
                </div>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteBook(book.id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No books available for the selected school and grade.</p>
        )}
      </main>
    </div>
  );
};

export default AdminAdd;
