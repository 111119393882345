import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { useAuth } from "../../contexts/authContext";
import { database } from "../../firebase/firebase";
import { useParams } from "react-router-dom";
import { FaShoppingCart, FaHome, FaUser, FaSignOutAlt, FaSearch, FaClipboardList } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { trackOrder } from "../../shipdayService";
import whatPng from '../../images/file.png';
import whaPng from "../../images/SD.png";

function OrderDetails() {
  const { orderId } = useParams(); 
  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [showLoginSignup, setShowLoginSignup] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (currentUser && orderId) {
      const orderRef = ref(database, `orders/${currentUser.uid}/${orderId}`);

      const fetchOrder = () => {
        onValue(orderRef, async (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setOrder(data);

            // Fetch the order status from the tracking service
            try {
              const status = await trackOrder(data.orderNumber); // Fetch the status
              setOrderStatus(status); // Set the status in the state
            } catch (error) {
              console.error("Error fetching order status:", error);
              setOrderStatus("Error fetching status");
            }
          }
        }, (error) => {
          console.error("Error fetching order:", error);
        });
      };

      fetchOrder(); // Initial fetch of order details
    }
  }, [currentUser, orderId]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container my-5">
       <header className="nus">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-3">
              <img src={whatPng} className="logo" alt="Logo" />
              <img
                src={whaPng}
                className="logo"
                style={{
                  width: '80px',
                  height: '120px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
                alt="Logo"
              />
            </div>
            <div className="col-md-9">
              <nav className="navbar navbar-expand-lg navbar-primary">
                <div
                  className={`collapse navbar-collapse ${showLoginSignup ? 'show' : ''
                    }`}
                  id="navbarTogglerDemo02"
                >
<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/home">
                      <FaHome className="icon" /> Home
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/school">
                      <FaSearch className="icon" /> School
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/cart">
                        <FaShoppingCart /> Cart
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/orders">
                        <FaClipboardList /> Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" onClick={handleLogout} to="/sign-in">
                      <FaSignOutAlt className="icon" /> Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <h1>Order Details</h1>
      <p><strong>Order Number:</strong> {order.orderNumber}</p>
      <p><strong>Status:</strong> {orderStatus || "Fetching status..."}</p>
      <p><strong>Total Cost:</strong> ${order.totalOrderCost}</p>
      <p><strong>Delivery Address:</strong> {order.customerAddress}</p>
      <p><strong>Delivery Instructions:</strong> {order.deliveryInstruction || "N/A"}</p>
      <h2>Order Items</h2>
      <ul>
        {order.orderItem.map((item, index) => (
          <li key={index}>
            {item.name} - ${item.unitPrice} x {item.quantity}
          </li>
        ))}
      </ul>
      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">&copy; 2024 Booklist Shop. All rights reserved.</p>
        </div>
      </footer>
      <div className="bottom-nav d-md-none nus">
        <Link className="nav-link" to="/home">
          <FaHome className="icon" />
          Home
        </Link>
        <Link className="nav-link" to="/school">
          <FaSearch className="icon" />
          School
        </Link>
        <Link className="nav-link" to="/cart">
          <FaShoppingCart className="icon" />
          Cart
        </Link>
        <Link className="nav-link" to="/orders">
          <FaClipboardList className="icon" />
          Orders
        </Link>
        <Link className="nav-link" onClick={handleLogout} to="/sign-in">
          <FaSignOutAlt className="icon" />
          Logout
        </Link>
      </div>
    </div>
  );
}

export default OrderDetails;
