import React, { useState, useEffect } from "react";
import {
  FaShoppingCart,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaUser,
  FaClipboardList,
  FaUniversity
} from "react-icons/fa";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Card,
  Table,
  Collapse,
} from "react-bootstrap";
import { database } from "../firebase/firebase"; // Adjust the path as necessary
import { ref, onValue, set, get } from "firebase/database";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/search.css";
import whatPng from "../images/file.png";
import whaPng from "../images/SD.png";

import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { useCart } from "../contexts/cartContext"; // Import useCart

const SchoolSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [requiredBooks, setRequiredBooks] = useState([]);
  const [allSchools, setAllSchools] = useState({});
  const [showBooks, setShowBooks] = useState(false);
  const [showGrades, setShowGrades] = useState(true);
  const [removedBooks, setRemovedBooks] = useState(new Set());
  const { addToCart } = useCart();
  const { currentUser } = useAuth();

  useEffect(() => {
    const filteredResults = Object.keys(allSchools).filter((schoolName) =>
      schoolName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setResults(filteredResults);
  }, [searchTerm, allSchools]);

  useEffect(() => {
    const schoolsRef = ref(database, "schools/");
    onValue(schoolsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setAllSchools(data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedSchool && selectedGrade) {
      const booksRef = ref(
        database,
        `schools/${selectedSchool.name}/grades/${selectedGrade}/books/`
      );
      onValue(booksRef, (snapshot) => {
        const books = snapshot.val() || {};
        const formattedBooks = Object.values(books).map((book) => ({
          name: book.name,
          price: book.price,
          imageUrl: book.imageUrl || "", // Add imageUrl if available
          type: book.type || "other", // Use 'type' for category
        }));

        // Group books by category
        const groupedBooks = {};
        formattedBooks.forEach((book) => {
          if (!groupedBooks[book.type]) {
            groupedBooks[book.type] = [];
          }
          groupedBooks[book.type].push(book);
        });

        // Define the desired order of categories
        const categoryOrder = ["Compulsory", "Stationery", "Complementary"];

        // Sort categories based on the defined order
        const sortedCategories = categoryOrder.filter((category) =>
          groupedBooks.hasOwnProperty(category)
        );

        setRequiredBooks(
          sortedCategories.map((category) => ({
            category,
            books: groupedBooks[category],
          }))
        );
      });
    } else {
      setRequiredBooks([]);
    }
  }, [selectedSchool, selectedGrade]);

  const handleSchoolSelect = (schoolName) => {
    const schoolData = allSchools[schoolName];
    setSelectedSchool({ name: schoolName, ...schoolData });
    setSelectedGrade(null);
    setRequiredBooks([]);
    setRemovedBooks(new Set());
    setShowBooks(false);
    setShowGrades(true);
  };

  const handleGradeSelect = (grade) => {
    setSelectedGrade(grade);
    setShowBooks(true);
    setShowGrades(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedSchool(null);
    setSelectedGrade(null);
    setRequiredBooks([]);
    setRemovedBooks(new Set());
    setShowBooks(false);
    setShowGrades(true);
  };

  const [showLoginSignup] = useState(false);

  const handleAddToCart = () => {
    // Generate books to add with a default quantity of 1 if not already set
    const booksToAdd = requiredBooks.reduce((acc, category) => {
      return [
        ...acc,
        ...category.books
          .filter((book) => !removedBooks.has(book.name))
          .map((book) => ({
            ...book,
            school: selectedSchool.name,
            grade: selectedGrade,
            quantity: book.quantity || 1, // Ensure quantity defaults to 1
          })),
      ];
    }, []);

    // Check if the user is logged in
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);

      // Use a single read and update operation to avoid loops
      get(cartRef)
        .then((snapshot) => {
          const existingCart = snapshot.val() || [];

          // Update the cart by merging existing items and new items
          const updatedCart = booksToAdd.reduce((acc, newBook) => {
            const existingItemIndex = acc.findIndex(
              (item) =>
                item.name === newBook.name &&
                item.school === newBook.school &&
                item.grade === newBook.grade
            );

            if (existingItemIndex !== -1) {
              // If the book is already in the cart, update the quantity
              acc[existingItemIndex].quantity += newBook.quantity;
            } else {
              // Otherwise, add the new book with a quantity of 1
              acc.push(newBook);
            }

            return acc;
          }, existingCart);

          // Update the cart in Firebase
          set(cartRef, updatedCart)
            .then(() => {
              addToCart(updatedCart); // Update the cart in local state
              alert("Books added to cart successfully.");
              navigate("/cart");
            })
            .catch((error) => {
              console.error("Error updating cart:", error);
              alert("Failed to add books to cart.");
            });
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    } else {
      alert("Please log in to save your cart.");
    }
  };
  const handleAddTypeToCart = (type) => {
    // Generate books to add with a default quantity of 1 if not already set
    const booksToAdd =
      requiredBooks
        .find((category) => category.category === type)
        ?.books.filter((book) => !removedBooks.has(book.name))
        .map((book) => ({
          ...book,
          school: selectedSchool.name,
          grade: selectedGrade,
          quantity: book.quantity || 1, // Ensure quantity defaults to 1
        })) || [];

    // Check if the user is logged in
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);

      // Use a single read and update operation to avoid loops
      get(cartRef)
        .then((snapshot) => {
          const existingCart = snapshot.val() || [];

          // Update the cart by merging existing items and new items
          const updatedCart = booksToAdd.reduce((acc, newBook) => {
            const existingItemIndex = acc.findIndex(
              (item) =>
                item.name === newBook.name &&
                item.school === newBook.school &&
                item.grade === newBook.grade
            );

            if (existingItemIndex !== -1) {
              // If the book is already in the cart, update the quantity
              acc[existingItemIndex].quantity += newBook.quantity;
            } else {
              // Otherwise, add the new book with a quantity of 1
              acc.push(newBook);
            }

            return acc;
          }, existingCart);

          // Update the cart in Firebase
          set(cartRef, updatedCart)
            .then(() => {
              addToCart(updatedCart); // Update the cart in local state
              alert(`${type} books added to cart successfully.`);
              navigate("/cart");
            })
            .catch((error) => {
              console.error("Error updating cart:", error);
              alert("Failed to add books to cart.");
            });
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    } else {
      alert("Please log in to save your cart.");
    }
  };

  const totalCost = requiredBooks.reduce((total, category) => {
    return (
      total +
      category.books
        .filter((book) => !removedBooks.has(book.name))
        .reduce((catTotal, book) => catTotal + book.price, 0)
    );
  }, 0);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Container>
      <header className="nus">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-3">
              <img src={whatPng} className="logo" alt="Logo" />
              <img
                src={whaPng}
                className="logo"
                style={{
                  width: "80px",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
                alt="Logo"
              />
            </div>
            <div className="col-md-9">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div
                  className={`collapse navbar-collapse ${
                    showLoginSignup ? "show" : ""
                  }`}
                  id="navbarTogglerDemo02"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/home">
                        <FaHome className="icon" /> Home
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/school">
                        <FaSearch className="icon" /> School
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/cart">
                        <FaShoppingCart /> Cart
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/orders">
                        <FaClipboardList /> Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={handleLogout}
                        to="/sign-in"
                      >
                        <FaSignOutAlt className="icon" /> Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <h1 className="text-center my-4">Find Your Child's School</h1>
      <Form onSubmit={(e) => e.preventDefault()} className="mb-4">
        <Row>
          <Col>
            <Form.Control
              type="text"
              placeholder="Enter school name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={() => {}}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      {selectedSchool ? (
        <div className="mt-4">
          <h3 className="text-center">
            Selected School: <span className="h2">{selectedSchool.name}</span>
          </h3>

          <div className="mt-3">
            <Collapse in={showGrades}>
              <div className="grades-container">
                <h4 className="text-center mb-4">Choose Your Grade:</h4>
                <Row className="justify-content-center">
                  {[
                    "PP1",
                    "PP2",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ].map((grade, index) => (
                    <Col xs={6} sm={4} md={3} key={index} className="mb-2">
                      <Button
                        variant={
                          selectedGrade === grade
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => handleGradeSelect(grade)}
                        className={`w-100 grade-button ${grade}-bg`}
                      >
                        {`Grade ${grade}`}
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            </Collapse>

            <Collapse in={showBooks}>
  <div className="mt-3">
    <h4 className="text-center">
      {selectedGrade ? `Books for Grade ${selectedGrade}` : "Required Books"}
    </h4>
    <Button
          variant="success"
          className="checkout-button mt-4"
          onClick={handleAddToCart}
        >
          Add All to Cart
        </Button>
    {requiredBooks.length > 0 ? (
      <>
        {requiredBooks.map((category, index) => (
          <div key={index}>
            <h5 className="mt-3"><strong>{category.category}</strong></h5>
            <div className="d-flex flex-wrap justify-content-start">
              {category.books.map((book, bookIndex) => (
                <div
                  key={bookIndex}
                  className="book-card me-3 mb-3"
                  style={{
                    width: "150px",
                    textAlign: "center",
                  }}
                >
                  {book.imageUrl && (
                    <img
                      src={book.imageUrl}
                      alt={book.name}
                      className="book-image"
                      style={{
                        width: "150px", // Fixed width for uniform size
                        height: "190px", // Fixed height for uniform size
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div className="mt-2">
                    {book.type === "Complementary" ? (
                      <span className="complementary-title">{book.name}</span>
                    ) : (
                      <span>{book.name}</span>
                    )}
                  </div>
                  <div className="text-end">
                    <span><strong>KES {book.price.toFixed(2)}</strong></span>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                onClick={() => handleAddTypeToCart(category.category)}
              >
                Add {category.category} to Cart
              </Button>
            </div>
          </div>
        ))}
        
      </>
    ) : (
      <p className="text-center">Currently not available.</p>
    )}
  </div>
</Collapse>

          </div>
        </div>
      ) : (
        <>
          {results.length > 0 ? (
            <>
              <h2 className="text-center my-4">Search Results</h2>
              <Row>
                {results.map((schoolName, index) => (
                  <Col md={4} key={index} className="mb-4">
                    <Card
                      onClick={() => handleSchoolSelect(schoolName)}
                      className="cursor-pointer"
                    >
                      <Card.Body>
                      <FaUniversity className="school-icon" />
                        <Card.Title> {schoolName}</Card.Title>
                        <Card.Text>{allSchools[schoolName].location}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <h2 className="text-center my-4">Suggested Schools</h2>
          )}

          <Row>
            {results.length === 0 &&
              Object.keys(allSchools)
                .slice(0, 3)
                .map((schoolName, index) => (
                  <Col md={4} key={index} className="mb-4">
                    <Card
                      onClick={() => handleSchoolSelect(schoolName)}
                      className="cursor-pointer"
                    >
                      <Card.Body>
                        <Card.Title><FaUniversity className="school-icon" />{schoolName}</Card.Title>
                        <Card.Text>{allSchools[schoolName].location}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
          </Row>
        </>
      )}
      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">
            &copy; 2024 Booklist Shop. All rights reserved.
          </p>
        </div>
      </footer>
      <div className="bottom-nav d-md-none">
        <Link className="nav-link" to="/home">
          <FaHome className="icon" />
          Home
        </Link>
        <Link className="nav-link" to="/school">
          <FaSearch className="icon" />
          School
        </Link>
        <Link className="nav-link" to="/cart">
          <FaShoppingCart className="icon" />
          Cart
        </Link>
        <Link className="nav-link" to="/orders">
          <FaUser className="icon" />
          Orders
        </Link>
        <Link className="nav-link" onClick={handleLogout} to="/sign-in">
          <FaSignOutAlt className="icon" />
          Logout
        </Link>
      </div>
    </Container>
  );
};

export default SchoolSearch;
