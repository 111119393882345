import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Home.css";
import whatPng from "../images/file.png";
import whaPng from "../images/SD.png";
import {
  FaShoppingCart,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaClipboardList,
  FaUniversity,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { getDatabase, ref, onValue } from "firebase/database"; // Import Firebase database functions

import one from "../images/1.jpeg";
import two from "../images/2.jpg";
import en from "../images/en.jpeg";
import setbook from "../images/setbook.webp";
import four from "../images/4.jpeg";
import schoolBagImg from "../images/schoolbag.jpg";
import laptopImg from "../images/Laptops.jpg";
import footballTshirtImg from "../images/jazzy.jpg";
import track from "../images/track.jpg";
import SportShoes from "../images/sportShoes.jpg";

const Home = () => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [showLoginSignup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userName, setUserName] = useState("");
  const [schools, setSchools] = useState([]);
  

  useEffect(() => {
    if (currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `users/${currentUser.uid}/displayName`);
      onValue(userRef, (snapshot) => {
        const name = snapshot.val();
        setUserName(name || "User");
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const db = getDatabase();
    const schoolsRef = ref(db, "schools");
    onValue(schoolsRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Fetched schools data:", data);
      if (data) {
        const schoolArray = Object.keys(data).map((key) => ({
          id: key,
          name: data[key].name || key, // Use name from data if available, otherwise use key
          ...data[key],
        }));
        console.log("Mapped schools array:", schoolArray);
        // Shuffle array and get the first 4 schools
        const shuffled = schoolArray.sort(() => 0.5 - Math.random());
        setSchools(shuffled.slice(0, 4));
      }
    });
  }, []);

  const handleSchoolClick = () => {
    navigate(`/school`); // Navigate to search page with school ID
  };


  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const featuredProducts = [
    {
      title: "Readers",
      image: setbook,
      price: "",
    },
    {
      title: "Encyclopedia",
      image: en,
      price: "",
    },
    {
      title: "Course Books",
      image: two,
      price: "",
    },
    {
      title: "Stationeries",
      image: four,
      price: "",
    },
  ];

  return (
    <div className="container-fluid">
      <header className="nus">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-3">
              <img src={whatPng} className="logo" alt="Logo" />
              <img
                src={whaPng}
                className="logo"
                style={{
                  width: "80px",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
                alt="Logo"
              />
            </div>
            <div className="col-md-9">
              <nav className="navbar navbar-expand-lg navbar-primary">
                <div
                  className={`collapse navbar-collapse ${
                    showLoginSignup ? "show" : ""
                  }`}
                  id="navbarTogglerDemo02"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/home">
                        <FaHome className="icon" /> Home
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/school">
                        <FaSearch className="icon" /> School
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/cart">
                        <FaShoppingCart /> Cart
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link className="nav-link" to="/orders">
                        <FaClipboardList /> Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={handleLogout}
                        to="/sign-in"
                      >
                        <FaSignOutAlt className="icon" /> Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <main className="mt-5">
        <div className="container">
          <div className="row maa">
            <div className="col-md-12">
              <div className="hero-section">
                <div className="hero-background"></div>
                <h1>Welcome Back {userName}!</h1>
                <p>Save the Hustle order your child's booklist From home.</p>
                <Link to="/school" className="btn btn-primary btn-lg">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
      <h2>Featured Schools</h2>
      <div className="common-schools">
        {schools.length > 0 ? (
          schools.map((school) => (
            <div
              key={school.id}
              className="school-item"
              onClick={() => handleSchoolClick()} // Add onClick handler
            >
              <FaUniversity className="school-icon" />
              <h3>{school.name || school.id}</h3>{" "}
              {/* Display school ID if name is not available */}
            </div>
          ))
        ) : (
          <p>No schools available</p>
        )}
      </div>
    </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <h2>Featured Products</h2>
              <div className="back-to-school-items">
                {featuredProducts.map((item, index) => (
                  <div key={index} className="item">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="item-image"
                    />
                    <h3>{item.title}</h3>
                    <p>{item.price}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">
            &copy; 2024 Booklist Shop. All rights reserved.
          </p>
        </div>
      </footer>
      <div className="bottom-nav d-md-none nus">
        <Link className="nav-link" to="/home">
          <FaHome className="icon" />
          Home
        </Link>
        <Link className="nav-link" to="/school">
          <FaSearch className="icon" />
          School
        </Link>
        <Link className="nav-link" to="/cart">
          <FaShoppingCart className="icon" />
          Cart
        </Link>
        <Link className="nav-link" to="/orders">
          <FaClipboardList className="icon" />
          Orders
        </Link>
        <Link className="nav-link" onClick={handleLogout} to="/sign-in">
          <FaSignOutAlt className="icon" />
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Home;
