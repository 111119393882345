import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaChartBar, FaClipboardList, FaSignOutAlt, FaPlusSquare } from 'react-icons/fa';
import { database } from '../firebase/firebase';
import '../components/Styles/ordersperschool.css';
import { Table } from 'react-bootstrap';

const OrdersPerSchool = () => {
  const [ordersBySchool, setOrdersBySchool] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = () => {
      const ordersRef = ref(database, 'orders/');
      onValue(ordersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const schoolOrderData = {};

          // Iterate over each user's orders
          Object.keys(data).forEach((userId) => {
            const userOrders = data[userId];

            // Iterate over each order
            Object.values(userOrders).forEach((order) => {
              const schoolName = order.restaurantName; // Assuming 'restaurantName' holds the school name
              const orderCost = order.totalOrderCost; // Assuming 'totalOrderCost' holds the order's total cost

              if (!schoolOrderData[schoolName]) {
                schoolOrderData[schoolName] = {
                  orderCount: 0,
                  totalIncome: 0,
                };
              }

              schoolOrderData[schoolName].orderCount += 1;
              schoolOrderData[schoolName].totalIncome += orderCost;
            });
          });

          setOrdersBySchool(schoolOrderData);
        }
        setLoading(false);
      }, (error) => {
        setError(error.message);
        setLoading(false);
      });
    };

    fetchOrders();
  }, []);

  const handleLogout = () => {
    // Implement logout logic here (e.g., clearing tokens, signing out from Firebase)
    navigate('/login');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="admin-dashboard">
      <aside className="sidebar">
        <h2>Admin Dashboard</h2>
        <nav>
          <ul>
            <li>
              <Link to="/admin"><FaHome /> Home</Link>
            </li>
            <li>
              <Link to="/admin/orders"><FaClipboardList /> Orders Per School</Link>
            </li>
            <li>
              <Link to="/admin/add"><FaPlusSquare /> Add School / Books</Link>
            </li>
            <li>
              <Link to="/admin/stats"><FaChartBar /> Statistics</Link>
            </li>
            <li>
              <Link onClick={handleLogout} to="/login"><FaSignOutAlt /> Logout</Link>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="content">
        <h2>Orders per School</h2>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>School Name</th>
              <th>Number of Orders</th>
              <th>Total Income</th>
            </tr>
          </thead>

          <tbody>
            {Object.entries(ordersBySchool).map(([schoolName, data]) => (
              <tr key={schoolName}>
                <td>{schoolName}</td>
                <td>{data.orderCount}</td>
                <td>${data.totalIncome.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </main>
    </div>
  );
};

export default OrdersPerSchool;
